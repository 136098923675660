import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "card mb-5 mb-xl-10",
  id: "kt_profile_details_view"
}
const _hoisted_2 = { class: "card-header cursor-pointer" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-bolder m-0" }
const _hoisted_5 = { class: "card-body p-9" }
const _hoisted_6 = { class: "row mb-7" }
const _hoisted_7 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_8 = { class: "col-lg-8" }
const _hoisted_9 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_10 = { class: "row mb-7" }
const _hoisted_11 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_12 = { class: "col-lg-8 d-flex align-items-center" }
const _hoisted_13 = { class: "fw-bolder fs-6 me-2" }
const _hoisted_14 = { class: "row mb-7" }
const _hoisted_15 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_16 = { class: "col-lg-8" }
const _hoisted_17 = { class: "fw-bolder fs-6" }
const _hoisted_18 = { class: "row gy-10 gx-xl-10" }
const _hoisted_19 = { class: "col-xl-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AccountInvoicesTable = _resolveComponent("AccountInvoicesTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.t("profileDetails")), 1)
        ]),
        _createVNode(_component_router_link, {
          to: "/account/settings",
          class: "btn btn-primary align-self-center"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("editProfile")), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.t("fullName")), 1),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.fullName), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.t("phoneNumber")), 1),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.subPhone), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.t("email")), 1),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.subEmail), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_component_AccountInvoicesTable, { "widget-classes": "card-xxl-stretch mb-5 mb-xl-10" })
      ])
    ])
  ], 64))
}