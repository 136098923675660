
import { computed, defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { replaceMonth } from "@/store/enums/months";
import { useI18n } from "vue-i18n";
import config from "@/core/config/APIConfig";

interface tableOrder {
  orderid: string
  date: {
    value: string,
    remarks?: string,
  },
  status: {
    label: string,
    color: string,
  },
  total: string,
}

export default defineComponent({
  name: "AccountInvoicesTable",
  components: {},
  props: {
    widgetClasses: String,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Overview", ["Account"]);
    });

    const subscriberDataOrders = computed(() => {
      const orders = store.getters.subscriberInfo.orders;
      if (!orders) {
        return [];
      }
      const arr: tableOrder[] = [];

      orders.forEach((item): any => {
        const createdDate = item.createdAt.slice(0, 4);
        let itemDate;
        if (createdDate) {
          itemDate =
            replaceMonth(item.createdAt.slice(5, 7)) +
            " " +
            item.createdAt.slice(8, 10) +
            ", " +
            createdDate;
        }
        arr.push({
          orderid: item.payment_id,
          date: {
            value: itemDate,
            remarks: "Paid",
          },
          status: {
            label: item.status,
            color: item.status === ("failed" && "canceled") ? "danger" : "success",
          },
          total: item.payment_cost,
        });
      });
      return arr;
    });

    const goToPaymentService = (paymentId) => {
      if (paymentId && config.yookassaSearch) {
        window.open(config.yookassaSearch + paymentId, "_blank");
      }
    };

    return {
      t,
      subscriberDataOrders,
      goToPaymentService,
    };
  },
});
