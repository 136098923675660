
import { computed, defineComponent, onMounted } from "vue";
import AccountInvoicesTable from "@/components/widgets/tables/AccountInvocesTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "account-overview",
  components: {
    AccountInvoicesTable,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    onMounted(() => {
      setCurrentPageBreadcrumbs(t("overview"), [t("account")]);
    });

    const actualUserData = computed(() => {
      return {
        fname: store.getters.subscriberInfo?.fname ? store.getters.subscriberInfo?.fname : '',
        lname: store.getters.subscriberInfo?.lname ? store.getters.subscriberInfo?.lname : '',
        email: store.getters.subscriberInfo?.email ? store.getters.subscriberInfo?.email : '',
        phone: store.getters.subscriberInfo?.phone ? store.getters.subscriberInfo?.phone : '',
        city: store.getters.subscriberInfo?.city ? store.getters.subscriberInfo?.city : '',
        country: store.getters.subscriberInfo?.country ? store.getters.subscriberInfo?.country : ''
      };
    });

    const subscriberData = computed(() => {
      return store.getters.subscriberInfo
    })

    const fullName = computed(() => {
      return actualUserData.value['fname'] + " " + actualUserData.value['lname']
    })

    const subEmail = computed(() => {
      return actualUserData.value['email']
        ? actualUserData.value['email']
        : "-";
    });

    const subPhone = computed(() => {
      return actualUserData.value['phone']
        ? actualUserData.value['phone']
        : "-";
    });

    return {
      subscriberData,
      fullName,
      subEmail,
      subPhone,
      t,
    };
  },
});
