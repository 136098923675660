import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-title align-items-start flex-column" }
const _hoisted_3 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_4 = { class: "card-body py-3" }
const _hoisted_5 = { class: "table-responsive" }
const _hoisted_6 = { class: "table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" }
const _hoisted_7 = { class: "fw-bolder text-muted" }
const _hoisted_8 = { class: "min-w-150px" }
const _hoisted_9 = { class: "min-w-140px" }
const _hoisted_10 = { class: "min-w-120px" }
const _hoisted_11 = { class: "min-w-120px" }
const _hoisted_12 = { class: "min-w-120px" }
const _hoisted_13 = { class: "text-dark fw-bolder fs-6" }
const _hoisted_14 = { class: "text-dark fw-bolder fs-6" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "btn btn-sm btn-light btn-active-light-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t("myInvoices")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("table", _hoisted_6, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", _hoisted_7, [
              _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.t("paymentId")), 1),
              _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.t("amount")), 1),
              _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.t("status")), 1),
              _createElementVNode("th", _hoisted_11, _toDisplayString(_ctx.t("date")), 1),
              _createElementVNode("th", _hoisted_12, _toDisplayString(_ctx.t("invoice")), 1)
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subscriberDataOrders, (item) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: item.orderid
              }, [
                _createElementVNode("td", null, [
                  _createElementVNode("span", _hoisted_13, _toDisplayString(item.orderid), 1)
                ]),
                _createElementVNode("td", _hoisted_14, _toDisplayString(item.total), 1),
                _createElementVNode("td", null, [
                  _createElementVNode("span", {
                    class: _normalizeClass([`badge-light-${item.status.color}`, "badge"])
                  }, _toDisplayString(item.status.label), 3)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("b", null, _toDisplayString(item.date.value), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("a", {
                    onClick: ($event: any) => (_ctx.goToPaymentService(item.orderid)),
                    class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
                  }, [
                    _createElementVNode("button", _hoisted_16, _toDisplayString(_ctx.t("download")), 1)
                  ], 8, _hoisted_15)
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 2))
}