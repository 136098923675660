enum months {
  Jan = "01",
  Feb = "02",
  Mar = "03",
  Apr = "04",
  May = "05",
  June = "06",
  July = "07",
  Aug = "08",
  Sep = "09",
  Oct = "10",
  Nov = "11",
  Dev = "12",
}

const replaceMonth = (number) => {
  for (const [propertyKey, propertyValue] of Object.entries(months)) {
    if (propertyValue == number) {
      // return->src 'media/svg/card-logos/mastercard.svg'
      return propertyKey;
    }
  }
};

export { months, replaceMonth };
